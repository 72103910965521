export enum HomeMenuPage {
  NONE = "",
  FEATURES = "features",
  PRODS = "products",
  PRODS_O = "products_one",
  PRODS_TW = "products_two",
  PRODS_T = "products_three",
  USEFULLS = "usefulls",
  EXPIRIENCE = "expirience"
}

export enum ControllerName {
  CORE = "/core",
  USERS = "/users",
  BRANCHES = "/branches",
  BLOG = "/blog",
  LOYALTY = "/loyalty",
  EVENTS = "/events",
  CRM = "/crm"
}

export enum StorageKeys {
  AUTHED = "nx_topgun_authed",
  USER = "nx_topgun_user",
  MAXMA_ACCOUNT = "nx_topgun_maxma_user",
  TOKEN = "nx_topgun_token",
  BRANCH_SEARCH = "nx_topgun_branch_search",
  BRANCHES = "nx_topgun_branches",
  BRANCHES_FILTERED = "nx_topgun_branches_filtered",

  REFERAL_ID = "nx_topgun_referal_id",
  TOTAL_SALE = "nx_topgun_total_sale",

  MONTH_STATS = "nx_topgun_months_stats",
  HELLO_WINDOW = "nx_topgun_hello_window",

  LOCK_SCREEN = "admin_lock_screen",

  SETTING_ADMIN_SIDEBAR = "nx_topgun_admin_sidebar_tgl",
  ADMIN_CRM_VIEW_TYPE = "nx_topgun_admin_crm_view_type",

  SETTING_TW = "nx_topgun_setting_tw",
  SETTING_TR = "nx_topgun_setting_tr",
  SETTING_FR = "nx_topgun_setting_fr",
  SETTING_HSL = "nx_topgun_setting_hsl",
  SETTING_DATE = "nx_topgun_setting_date",

  SETTING_HOME_EVENTS = "nx_topgun_home_events",
  SETTING_HOME_COLLABS = "nx_topgun_home_collabs",
  SETTING_FRAN_EVENTS = "nx_topgun_fran_events",
  SETTING_FRAN_COLLABS = "nx_topgun_fran_collabs",
  SETTING_VAC_SLIDER = "nx_topgun_vac_slider",
  SETTING_VAC_LIST = "nx_topgun_vac_list",
  SETTING_MAXMA_CONF = "nx_topgun_maxma_conf",

  LAST_VISIT_DATE = "nx_topgun_last_visit_date",

  LOCALE = "nx_topgun_locale"
}

// export const API_URL = "http://localhost:4448"
export const API_URL = "https://api.topgun.ru"
export const NEW_API_URL = "https://dev.api-crm.topgun.ru"

export const IS_DEV = true

export const APP_VERSION = "1.2.0"

export const layout = [
  { i: "a", x: 0, y: 0, w: 1, h: 1, static: true },
  { i: "b", x: 1, y: 0, w: 1, h: 1, static: true },
  { i: "c", x: 2, y: 0, w: 1, h: 1, static: true },
  { i: "d", x: 3, y: 0, w: 2, h: 1, static: true },

  { i: "e", x: 0, y: 1, w: 1, h: 1, static: true },
  { i: "f", x: 1, y: 1, w: 1, h: 1, static: true },
  { i: "g", x: 2, y: 1, w: 1, h: 1, static: true },
  { i: "h", x: 3, y: 1, w: 1, h: 1, static: true },
  { i: "i", x: 4, y: 1, w: 1, h: 1, static: true },

  { i: "j", x: 0, y: 2, w: 2, h: 1, static: true },
  { i: "k", x: 2, y: 2, w: 1, h: 1, static: true },
  { i: "l", x: 3, y: 2, w: 1, h: 1, static: true },
  { i: "m", x: 4, y: 2, w: 1, h: 1, static: true }
];

export const layoutTablet = [
  { i: "a", x: 0, y: 0, w: 1, h: 1, static: true },
  { i: "b", x: 1, y: 0, w: 1, h: 1, static: true },
  { i: "c", x: 2, y: 0, w: 2, h: 1, static: true },

  { i: "d", x: 0, y: 1, w: 1, h: 1, static: true },
  { i: "e", x: 1, y: 1, w: 1, h: 1, static: true },
  { i: "f", x: 2, y: 1, w: 1, h: 1, static: true },
  { i: "g", x: 3, y: 1, w: 1, h: 1, static: true },

  { i: "h", x: 0, y: 2, w: 1, h: 1, static: true },
  { i: "i", x: 1, y: 2, w: 2, h: 1, static: true },
  { i: "j", x: 3, y: 2, w: 1, h: 1, static: true },

  { i: "k", x: 0, y: 3, w: 1, h: 1, static: true },
  { i: "l", x: 1, y: 3, w: 1, h: 1, static: true },
  { i: "m", x: 2, y: 3, w: 1, h: 1, static: true },
  { i: "n", x: 3, y: 3, w: 1, h: 1, static: true }
];

export const layoutMobile = [
  { i: "a", x: 0, y: 0, w: 1, h: 1, static: true },
  { i: "b", x: 1, y: 0, w: 1, h: 1, static: true },
  { i: "c", x: 2, y: 0, w: 1, h: 1, static: true },
  { i: "d", x: 3, y: 0, w: 2, h: 1, static: true },

  { i: "e", x: 0, y: 1, w: 1, h: 1, static: true },
  { i: "f", x: 1, y: 1, w: 1, h: 1, static: true },
  { i: "g", x: 2, y: 1, w: 1, h: 1, static: true },
  { i: "h", x: 3, y: 1, w: 1, h: 1, static: true },
  { i: "i", x: 4, y: 1, w: 1, h: 1, static: true },

  { i: "j", x: 0, y: 2, w: 2, h: 1, static: true },
  { i: "k", x: 2, y: 2, w: 1, h: 1, static: true },
  { i: "l", x: 3, y: 2, w: 1, h: 1, static: true },
  { i: "m", x: 4, y: 2, w: 1, h: 1, static: true }
];

export const VideosList: any[] = [
  {
    url: "https://youtu.be/N2kqa_fkkNc",
    image: "/assets/images/videos_preview/1.jpg",
    title: "«TOP GUN - Мэверик»",
    pad: 0
  }, {
    url: "https://www.youtube.com/watch?v=q6T3Su9beqU&t=1s",
    image: "/assets/images/videos_preview/2.webp",
    title: "«Джентльмены»",
    pad: 1
  }, {
    url: "https://youtu.be/00bD36GONwY",
    image: "/assets/images/events/16.png",
    title: "«Последний богатырь: Корень зла»<br/>с участием Алексея Локонцева",
    pad: 0
  }, {
    url: "https://www.youtube.com/watch?v=foAoAsgU0NA",
    image: "/assets/images/videos_preview/4.webp",
    title: "Коллаборация TOPGUN<br/>с фильмом Стекло",
    pad: 1
  }
]